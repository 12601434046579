<template>
	<div class="row justify-around q-px-sm">
		<q-btn
			v-for="btn in btn_data"
			:key="btn.label"
			outline
			rounded
			stack
			class="col-sm-3 col-xs-12 q-ma-md"
			color="primary"
			size='md'
			:icon='btn.icon'
			:label="btn.label"
			:to="btn.to"
			@click="collapse_sidebar_item({
				'sidebar_parent_id': btn.sidebar_parent_id,
				'sidebar_child_id': btn.sidebar_child_id
			})"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: "DashboardTabs",
	data(){
		return{
			btn_data: [
				{
					'label': 'Start ' + this.$store.getters.module_name,
					'icon': 'flash_on',
					'to': {'name': 'Start'},
					'sidebar_parent_id': 3,
					'sidebar_child_id': 301,
				},
				{
					'label': 'Reports',
					'icon': 'pie_chart',
					'to': {'name': 'Reports'},
					'sidebar_parent_id': 3,
					'sidebar_child_id': 302,
				}
			],
		}
	},
	methods:{
		...mapActions(['collapse_sidebar_item']),
	},
}
</script>

<style lang="scss" scoped>
</style>
